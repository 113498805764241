@import '../../../../node_modules/@fluentui/react/dist/sass/References';

// GRID INFORMATION FOR DOCUMENT VIEW
$ribbon-height: 93px;
$doc-menu-height: 60px;
$taskpane-width: 329px;
$doc-view-width: 852px;
$doc-view-top-gap: 20px;
$doc-view-bottom-gap: 10px;
$doc-view-filmstrip-width: 70px;
$doc-view-feedback-buttons-width: 70px;
$doc-view-filmstrip-vert-pad: 73px;

$grid-area-ribbon: "ribbon";
$grid-area-doc-menu: "doc-menu";
$grid-area-taskpane: "taskpane";
$grid-area-doc-view: "doc-view";
$grid-area-doc-view-filmstrip: "filmstrip";
$grid-area-feedback-buttons: "feedback-buttons";
$grid-area-large-chunks: "large-chunks";
$grid-area-doc-view-right-gutter: "right-gutter";
$grid-area-doc-view-menu-background: "menu-background";

$grid-area-home-button: "HomeButton";
$grid-area-cluster-strip: "ClusterStrip";
$grid-area-next-button: "NextDoc";
$grid-area-search-text-field: "SearchTextField";

$grid-area-results: "Results";

// GRID INFORMATION FOR WORD TASKPANE
$taskpane-page-tabstrip-height: 30px;
$taskpane-page-subheading-tabstrip-height: 30px;
$grid-area-tabstrip: "taskpane-tabstrip";
$grid-area-subheading-tabstrip: "taskpane-subheading-tabstrip";

.cookieContent {
    max-width: 80%;
}

.cookie-confirmation-display {
    display: none;
}

button:focus {
    outline: none;
}

body {
    height: 100vh;
    margin: 0;
    overflow-y: hidden;
}

.noscroll {
    overflow: hidden;
}

.scrollYOnHover {
    overflow: hidden;

    &:hover {
        overflow-y: auto;
    }
}

.shellFullWindow {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    background: white;
    display: flex;
    flex-direction: column;
}

.actionable {
    cursor: pointer;
}

.shellAppSpace {
    display: flex;
    width: 100%;
    flex: 1 0;
    min-height: 0;
}

.documentMenu {
    width: 100%;
    height: $doc-menu-height;
    grid-area: #{$grid-area-doc-menu};
    align-content: center;
}

.wordTaskpane {
    height: 100%;
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    .taskpaneHeaderTabstrip {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;

        %tabBase {
            height: 100%;
            width: 100%;
            text-align: center;
            flex: 1;
        }

        .pageTabGap {
            height: 100%;
            width: 2px;
        }

        .taskpaneHeaderTabCurrentPage {
            @extend %tabBase;
            background: rgb(225, 235, 117);
        }

        .taskpaneHeaderTabNotCurrentPage {
            @extend %tabBase;
            transition: background 0.2s ease-in-out;
            cursor: pointer;

            &:hover {
                background: rgba(225, 235, 117, 0.8);
            }

            background: rgba(225, 235, 117, 0.6);
        }

        .taskpaneHeaderTabText {
            font-weight: 600;
            line-height: $taskpane-page-tabstrip-height;
        }
    }

    .taskpanePage {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;

        .taskpaneSubHeaderTabstripContainer {
            height: $taskpane-page-subheading-tabstrip-height;
            width: 100%;
        }

        .taskpaneSubHeaderTabstrip {
            background: #F2F2F2;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;

            %tabBase {
                height: 100%;
                width: 100%;
                text-align: center;
                flex: 1;
            }

            .taskpaneSubHeaderTabCurrent {
                @extend %tabBase;
                text-decoration: underline;
            }

            .taskpaneSubHeaderTabNotCurrent {
                @extend %tabBase;
                transition: text-decoration 0.2s ease-in-out;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                    text-decoration-color: rgba(0, 0, 0, 0.4);
                }
            }

            .taskpaneSubHeaderTabText {
                line-height: $taskpane-page-subheading-tabstrip-height;
            }
        }
    }

    .gap {
        height: 10px;
        width: 100%;
    }

    .cardFooter {
        height: 20px;
        display: flex;
        flex-direction: row;

        .info {
            flex: 1;
            width: 100%;
            height: 100%;
        }

        .action {
            width: 20px;
            height: 100%;
        }
    }

    .taskpanePageBody {
        overflow-y: hidden;
        padding-left: 7px;
        padding-right: 7px;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .clauseListContainer {
        overflow-y: hidden;
        overflow-x: hidden;
        width: 100%;
        height: 100%;
        padding-top: 7px;

        .clauseList {
            width: 100%;
            height: 100%;
            overflow-y: hidden;
            overflow-x: hidden;

            &:hover {
                overflow-y: auto;
            }

            .variantHeader {
                cursor: pointer;
                display: flex;
                flex-direction: row;
            }

            .headerText {
                flex: 1;
            }

            .chevronContainer {
                width: 30px;
            }

            %headerChevron {
                transition: transform 0.1s linear;
            }

            .chevronClosed {
                @extend %headerChevron;
                transform: rotate(0deg);

            }

            .chevronOpened {
                @extend %headerChevron;
                transform: rotate(90deg);
            }
        }
    }

    i {
        font-size: 15px;
        padding: 2px;
    }

    .cardTitle {
        font-size: 19px;
    }

    .wordTaskpaneCard {
        width: 100%;
    }

    .wordTaskpaneCardOpen {
        flex: 1;
        min-height: 250px;
        display: flex;
        flex-direction: column;
        font-size: '19px';
    }

    .wordTaskpaneCardClosed {
        height: 50px;
    }

    .cardContentContainer {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        flex: 1;

        .wordList {
            height: 100%;
            width: 100%;
            overflow-y: auto;
        }

        %itemContentBase {
            min-height: 30px;
        }

        .itemContent {
            @extend %itemContentBase;
            padding: 10px;
            margin-top: 5px;
        }

        .clickableItemConten {
            @extend .itemContent;
            cursor: pointer;
        }

        .textWithPasteButton {
            @extend %itemContentBase;
            display: grid;
            grid-template-columns: 40px auto;
            margin-top: 10px;
            margin-right: 10px;
            border-style: solid;
            border-color: #95989A;
            border-width: 1px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: 5px;
            background: #f0f0f1;
        }
    }

    .textItem {
        .actualText {
            all: initial;
        }
    }
}
