$header-height: 50px;

header {
    height: $header-height;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .left {
        padding-left: 10px;
    }

    .center {
        flex: 1;
        padding-left: 70px;
        text-align: center;
    }

    .right {
        padding-right: 15px;
    }

    .docugamiBrandedText {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 34px;
    }

    .dgnotification {
        position: relative;
        display: inline-block;
    }

    .dgnotification .dgbadge {
        position: absolute;
        top: -6px;
        right: -13px;
        padding: 4px 8px;
        border-radius: 50%;
        background-color: #0085FF;
        color: white;
        font-size: 10px;
    }
}

.officeHeader {
    height: 38px;
}
